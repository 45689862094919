import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Paper, Typography, CircularProgress, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getNotice, processNotice } from '../../utils/apiRoutes';

const AnalysisPage = () => {
    const { noticeId } = useParams();
    const navigate = useNavigate();
    const [notice, setNotice] = useState(null);
    const [processing, setProcessing] = useState(true);

    useEffect(() => {
        document.title = 'Thermo Fisher AI Editais'
    }, [])

    const fetchNotice = async (noticeId) => {
        try {
            const response = await getNotice(noticeId);
            if (response.data.notice.analysis) {
                setNotice(response.data.notice);
                setProcessing(false);
                return;
            }
        } catch (error) {
            toast.error('Falha ao buscar edital.');
            setTimeout(() => {
                navigate('/editais')
            }, 1500);
        }
    };

    const startProcessNotice = async (noticeId) => {
        try {
            const payload = { notice_id: parseInt(noticeId) };
            const response = await processNotice(payload);
            setNotice(response.data[0].notice);
            setProcessing(false);
        } catch (error) {
            toast.error('Falha ao processar edital.');
            setTimeout(() => {
                navigate('/editais')
            }, 1500);
        }
    };

    useEffect(() => {
        if (noticeId) {
            const needProcess = localStorage.getItem('processNotice');
            if (needProcess) {
                localStorage.removeItem('processNotice');
                startProcessNotice(noticeId);
            } else {
                fetchNotice(noticeId);
            }
        }
    }, [noticeId]);

    const handleBack = () => {
        navigate(`/editais`);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', overflow: "auto", padding: 2 }}>
            <ToastContainer />
            <Paper sx={{ padding: 4, width: '100%', maxWidth: 1400, boxShadow: 3, overflowX: 'auto', margin: { xs: 1, sm: 2 } }}>
                {processing ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
                        <Typography variant="h6" gutterBottom>
                            Processando arquivo, aguarde... <CircularProgress size={18} />
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ position: 'relative', padding: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <IconButton onClick={handleBack} sx={{ marginRight: 1, marginBottom: 1 }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6" gutterBottom>
                                {notice.title} | {notice.type} {notice.from_type ? ` - ${notice.from_type}` : ''}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            dangerouslySetInnerHTML={{ __html: notice ? notice.analysis : '' }}
                            sx={{
                                padding: 2,
                                border: '1px solid #ccc',
                                borderRadius: 2,
                                backgroundColor: '#fff',
                                overflow: 'auto',
                                maxHeight: '70vh',
                                width: '100%',
                                margin: '0 auto',
                                '& table': {
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    marginTop: '20px',
                                },
                                '& th, & td': {
                                    border: '1px solid #ddd',
                                    textAlign: 'left',
                                    padding: '8px',
                                },
                                '& th': {
                                    backgroundColor: '#f2f2f2',
                                },
                                '& tr:nth-of-type(even)': {
                                    backgroundColor: '#f9f9f9',
                                },
                                '& tr:hover': {
                                    backgroundColor: '#f1f1f1',
                                },
                            }}
                        />
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default AnalysisPage;
