import React, {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { Grid, Button, IconButton, CircularProgress } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import apiBaseUrl from '../../utils/apiConfig';
import './avatar.scss'

let peerConnection;
let streamId;
let sessionId;
let sessionClientAnswer;

let statsIntervalIds = [];
let videoIsPlaying;
let lastBytesReceived;

const AvatarContainer = forwardRef(({ eventUser, modalOpen }, ref) => {
    const [txtDivConnect, setTxtDivConnect] = useState('Connect');
    const [streamConnected, setStreamConnected] = useState(false);
    const [isAvatarVisible, setIsAvatarVisible] = useState(true);

    const talkVideoRef = useRef(null);
    const peerStatusLabelRef = useRef(null);
    const iceStatusLabelRef = useRef(null);
    const iceGatheringStatusLabelRef = useRef(null);
    const signalingStatusLabelRef = useRef(null);
    const streamingStatusLabelRef = useRef(null);
    const isAvatarVisibleRef = useRef(isAvatarVisible);

    const avatarContainerRef = useRef(null);

    useEffect(() => {
        isAvatarVisibleRef.current = isAvatarVisible;
    }, [isAvatarVisible]);

    useEffect(() => {
        if (isAvatarVisible) {
            connectToStream();
        } else {
            stopAllStreams();
            closePC();
            setStreamConnected(false);
        }
    }, [isAvatarVisible]);

    useEffect(() => {
        if (streamConnected && !modalOpen && eventUser) {
            talkButton(
                `Olá ${eventUser.name}, sou sua assistente virtual. Se precisar de algo pode me perguntar`
            );
        }
    }, [streamConnected, modalOpen, eventUser]);

    useImperativeHandle(ref, () => ({
        talkButton,
        isAvatarVisible: () => isAvatarVisible,
    }));

    function stopAllStreams() {
        if (talkVideoRef.current?.srcObject) {
            talkVideoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            talkVideoRef.current.srcObject = null;
        }
    }

    function closePC(pc = peerConnection) {
        if (!pc) return;

        pc.close();

        pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
        pc.removeEventListener('icecandidate', onIceCandidate, true);
        pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
        pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
        pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
        pc.removeEventListener('track', onTrack, true);

        // Clear all intervals
        statsIntervalIds.forEach((id) => clearInterval(id));
        statsIntervalIds = [];

        if (iceGatheringStatusLabelRef.current)
            iceGatheringStatusLabelRef.current.innerText = '';
        if (signalingStatusLabelRef.current) signalingStatusLabelRef.current.innerText = '';
        if (iceStatusLabelRef.current) iceStatusLabelRef.current.innerText = '';
        if (peerStatusLabelRef.current) peerStatusLabelRef.current.innerText = '';

        if (pc === peerConnection) {
            peerConnection = null;
        }
    }

    const maxRetryCount = 3;
    const maxDelaySec = 4;

    async function fetchWithRetries(url, options, retries = 1) {
        try {
            return await fetch(url, options);
        } catch (err) {
            if (retries <= maxRetryCount) {
                const delay =
                    Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;

                await new Promise((resolve) => setTimeout(resolve, delay));

                console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
                return fetchWithRetries(url, options, retries + 1);
            } else {
                throw new Error(`Max retries exceeded. error: ${err}`);
            }
        }
    }

    function onIceGatheringStateChange() {
        if (iceGatheringStatusLabelRef.current) {
            iceGatheringStatusLabelRef.current.innerText = peerConnection.iceGatheringState;
            iceGatheringStatusLabelRef.current.className =
                'iceGatheringState-' + peerConnection.iceGatheringState;
        }
    }

    function onIceCandidate(event) {
        if (event.candidate) {
            const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

            const iceRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    stream_id: streamId,
                    candidate,
                    sdpMid,
                    sdpMLineIndex,
                    session_id: sessionId,
                }),
            };

            fetch(`${apiBaseUrl}/avatar/stream/ice`, iceRequestOptions);
        }
    }

    function onIceConnectionStateChange() {
        let peerIceConnectionState = peerConnection.iceConnectionState;
        if (iceStatusLabelRef.current) {
            iceStatusLabelRef.current.innerText = peerIceConnectionState;
            iceStatusLabelRef.current.className =
                'iceConnectionState-' + peerIceConnectionState;
        }

        console.log('peerIceConnectionState', peerIceConnectionState);

        if (
            peerIceConnectionState === 'failed' ||
            peerIceConnectionState === 'closed' ||
            peerIceConnectionState === 'disconnected'
        ) {
            // Clear all intervals
            statsIntervalIds.forEach((id) => clearInterval(id));
            statsIntervalIds = [];

            stopAllStreams();
            closePC();
            setStreamConnected(false);

            if (isAvatarVisibleRef.current) {
                console.log('onIceConnectionStateChange', isAvatarVisibleRef.current);
                connectToStream();
            }
        } else if (peerIceConnectionState === 'connected') {
            setStreamConnected(true);
        }
    }

    function onConnectionStateChange() {
        // not supported in firefox
        let peerConnectionState = peerConnection.connectionState;
        if (peerStatusLabelRef.current) {
            peerStatusLabelRef.current.innerText = peerConnectionState;
            peerStatusLabelRef.current.className =
                'peerConnectionState-' + peerConnectionState;
        }

        // console.log('peerConnectionState', peerConnectionState);

        if (
            peerConnectionState === 'failed' ||
            peerConnectionState === 'closed' ||
            peerConnectionState === 'disconnected'
        ) {
            statsIntervalIds.forEach((id) => clearInterval(id));
            statsIntervalIds = [];

            stopAllStreams();
            closePC();
            setStreamConnected(false);

            if (isAvatarVisibleRef.current) {
                console.log('onConnectionStateChange', isAvatarVisibleRef.current);
                connectToStream();
            }
        } else if (peerConnectionState === 'connected') {
            setStreamConnected(true);
        }
    }

    function onSignalingStateChange() {
        if (signalingStatusLabelRef.current) {
            signalingStatusLabelRef.current.innerText = peerConnection.signalingState;
            signalingStatusLabelRef.current.className =
                'signalingState-' + peerConnection.signalingState;
        }
    }

    function playIdleVideo() {
        const idleVideo = document.createElement('video');
        idleVideo.src =
            'https://cisp1-static.s3.sa-east-1.amazonaws.com/avatar_thermo_idle.mp4';

        idleVideo.oncanplay = () => {
            if (talkVideoRef && talkVideoRef.current) {
                talkVideoRef.current.classList.add('fade-out');

                setTimeout(() => {
                    talkVideoRef.current.srcObject = undefined;
                    talkVideoRef.current.src = idleVideo.src;
                    talkVideoRef.current.loop = true;

                    if (!modalOpen) talkVideoRef.current.play();

                    talkVideoRef.current.classList.remove('fade-out');
                    talkVideoRef.current.classList.add('fade-in');
                }, 300);
            }

        };
    }

    function onVideoStatusChange(videoIsPlaying, stream) {
        let status;

        if (videoIsPlaying) {
            status = 'streaming';
            const remoteStream = stream;

            setVideoElement(remoteStream);
        } else {
            status = 'empty';
            playIdleVideo();
        }

        if (streamingStatusLabelRef.current) {
            streamingStatusLabelRef.current.innerText = status;
            streamingStatusLabelRef.current.className = 'streamingState-' + status;
        }
    }

    function setVideoElement(stream) {
        if (!stream) return;
        talkVideoRef.current.srcObject = stream;
        talkVideoRef.current.loop = false;

        // safari hotfix
        if (talkVideoRef.current.paused) {
            talkVideoRef.current.play().then(() => { }).catch((e) => { });
        }
    }

    function onTrack(event) {
        if (!event.track) return;

        const intervalId = setInterval(async () => {
            try {
                if (!isAvatarVisibleRef.current || !peerConnection) {
                    clearInterval(intervalId);
                    return;
                }

                const stats = await peerConnection.getStats(event.track);

                stats.forEach((report) => {
                    if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
                        const videoStatusChanged =
                            videoIsPlaying !== (report.bytesReceived > lastBytesReceived);

                        if (videoStatusChanged) {
                            videoIsPlaying = report.bytesReceived > lastBytesReceived;
                            onVideoStatusChange(videoIsPlaying, event.streams[0]);
                        }
                        lastBytesReceived = report.bytesReceived;
                    }
                });
            } catch (error) {
                console.error('Error during streaming setup:', error);
                clearInterval(intervalId);

                if (isAvatarVisibleRef.current) {
                    console.log('onTrack', isAvatarVisibleRef.current);
                    connectToStream();
                }
            }
        }, 500);

        statsIntervalIds.push(intervalId);
    }

    async function createPeerConnection(offer, iceServers) {
        if (!peerConnection) {
            peerConnection = new RTCPeerConnection({ iceServers });
            peerConnection.addEventListener(
                'icegatheringstatechange',
                onIceGatheringStateChange,
                true
            );
            peerConnection.addEventListener('icecandidate', onIceCandidate, true);
            peerConnection.addEventListener(
                'iceconnectionstatechange',
                onIceConnectionStateChange,
                true
            );
            peerConnection.addEventListener('connectionstatechange', onConnectionStateChange, true);
            peerConnection.addEventListener('signalingstatechange', onSignalingStateChange, true);
            peerConnection.addEventListener('track', onTrack, true);
        }

        await peerConnection.setRemoteDescription(offer);
        const sessionClientAnswer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(sessionClientAnswer);

        return sessionClientAnswer;
    }

    const connectToStream = async () => {
        if (peerConnection && peerConnection.connectionState === 'connected') {
            return;
        }

        setTxtDivConnect('Connecting...');
        stopAllStreams();
        closePC();

        const formData = new FormData();

        const requestOptions = {
            method: 'POST',
            body: formData,
        };

        const sessionResponse = await fetchWithRetries(
            `${apiBaseUrl}/avatar/stream`,
            requestOptions
        );

        const {
            newStreamId: newStreamId,
            offer,
            iceServers: iceServers,
            newSessionId: newSessionId,
        } = await sessionResponse.json();
        streamId = newStreamId;
        sessionId = newSessionId;

        try {
            sessionClientAnswer = await createPeerConnection(offer, iceServers);
            setTxtDivConnect('Connected');
            setStreamConnected(true);
        } catch (e) {
            console.log('error during streaming setup', e);
            stopAllStreams();
            closePC();
            setStreamConnected(false);
            console.log('trying again...');

            if (isAvatarVisibleRef.current) connectToStream();

            return;
        }

        const sdpRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                stream_id: streamId,
                answer: sessionClientAnswer,
                session_id: sessionId,
            }),
        };

        await fetch(`${apiBaseUrl}/avatar/stream/sdp`, sdpRequestOptions);
    };

    const talkButton = async (message) => {
        if (
            peerConnection?.signalingState === 'stable' ||
            peerConnection?.iceConnectionState === 'connected'
        ) {
            const formData = new FormData();

            formData.append('stream_id', streamId);
            formData.append('session_id', sessionId);
            formData.append('expression', 'neutral');
            formData.append('message', message);

            const requestOptions = {
                method: 'POST',
                body: formData,
            };

            fetch(`${apiBaseUrl}/avatar/stream/talk`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`${response.error}`);
                    }

                    return response.json();
                })
                .then((data) => {
                    if (data.message === 'Too many requests.') {
                        // Handle too many requests
                    }
                })
                .catch((e) => {
                    // alert(e.error + '. Refresh page');
                });
        }
    };

    const handleCloseAvatar = () => {
        setIsAvatarVisible(false);

        if (avatarContainerRef.current) {
            avatarContainerRef.current.classList.add('no-avatar');
        }
    };

    const handleOpenAvatar = () => {
        setIsAvatarVisible(true);

        if (avatarContainerRef.current) {
            avatarContainerRef.current.classList.remove('no-avatar');
        }
    };

    return (
        <Grid container style={{ position: 'relative' }}>
            <div ref={avatarContainerRef} className="video-container">
                {isAvatarVisible ? (
                    <>
                        {!streamConnected ? (
                            <div className="video-loading">
                                <CircularProgress size={48} />
                            </div>
                        ) : null}

                        <video
                            id="talk-video"
                            playsInline
                            width="100%"
                            height="100%"
                            autoPlay
                            className="video-content"
                            ref={talkVideoRef}
                        >
                            <source src="" type="video/mp4" />
                            Seu navegador não suporta a reprodução de vídeo.
                        </video>
                        <Button
                            variant="contained"
                            color="error"
                            className="close-button"
                            onClick={handleCloseAvatar}
                        >
                            fechar
                        </Button>
                    </>
                ) : (
                    <IconButton color="error" onClick={handleOpenAvatar} className="icon-button">
                        <AccountCircleIcon fontSize="large" />
                    </IconButton>
                )}
            </div>

            <div
                id="status"
                style={{
                    position: 'absolute',
                    left: '2%',
                    top: '0',
                    color: 'white',
                    marginTop: '35%',
                    marginLeft: '1%',
                    display: 'none'
                }}
            >
                Status: {txtDivConnect}
                <br />
                ICE gathering status:{' '}
                <label id="ice-gathering-status-label" ref={iceGatheringStatusLabelRef}></label>
                <br />
                ICE status: <label id="ice-status-label" ref={iceStatusLabelRef}></label>
                <br />
                Peer connection status:{' '}
                <label id="peer-status-label" ref={peerStatusLabelRef}></label>
                <br />
                Signaling status:{' '}
                <label id="signaling-status-label" ref={signalingStatusLabelRef}></label>
                <br />
                Streaming status:{' '}
                <label id="streaming-status-label" ref={streamingStatusLabelRef}></label>
                <br />
            </div>
        </Grid>
    );
});

export default AvatarContainer;
